<template>
  <div class="page-out">
    <div class="page-box" :class="isPcMobileClass()">
      <div class="img-box">
        <img
          :src=" $route.query.type === '1' ? successImg : failImg"
          alt=""
        >
      </div>
      <div class="page-title">
        {{ $route.query.text}}
      </div>
    </div>
  </div>
</template>

<script>
import { isPcMobile } from '@/lib/until.js';
export default {
  name: 'StutesPage',
  data() {
    return {
      successImg: require('../../assets/img/icon_tips_success@2x.png'),
      failImg: require('../../assets/img/icon_tips_wrong@2x.png'),
    }
  },
  methods: {
    isPcMobileClass() {
      return isPcMobile() === 2 ? 'screenControl' : '';
    }
  }
}
</script>

<style scoped lang="scss">
.page-out{
  background-color: $color-F7;
  .page-box{
    height: 100vh;
    overflow: hidden;
    background-color: $color-FFF;
    border-radius: 8px 8px 0 0;
    .img-box{
      width: 60px;
      height: 60px;
      margin: 84px auto;
      margin-bottom: 24px;
      img {
        width: 60px;
      }
    }
    .page-title{
      color: #23252E;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
</style>